<template>
  <base-info-card title="Post Categories">
    <ul class="pl">
      <li
        v-for="category in categories"
        :key="category"
        class="mb-3"
        v-text="category"
      />
    </ul>
  </base-info-card>
</template>

<script>
  export default {
    name: 'NewsCategories',

    data: () => ({ }),

    computed: {
      categories () {
        return this.$store.getters['news/categories']
      },
    },

    mounted () {
      this.fetchCategories()
    },

    methods: {
      fetchCategories () {
        return this.$store.dispatch('news/getCategories')
      },
    },
  }
</script>
